import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import Loader from "./loader";
import "./index.css";
import { BroadcastChannel } from "broadcast-channel";

const App = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (loading) {
      const channel = new BroadcastChannel("app-data");

      console.log(queryString.parse(location.search), "params")
      channel.postMessage(queryString.parse(location.search));
    }
  }, [loading]);



  useEffect(() => {
    if (queryString.parse(location.search)?.sredref) {
      setLoading(true);
    }
  }, []);

  return (
    <>
      {loading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            flexDirection: "column",
            background: "white",
          }}
        >
          <Loader />
        </div>
      )}
    </>
  );
};

export default App;
