import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { axiosInstance } from "./utils";
import Loader from "./loader";
import axios from "axios";
import "./index.css";
import { BroadcastChannel } from "broadcast-channel";

const App = () => {
  const params = useParams();
  const [silientHtml, setSilientHtml] = useState("");
  const [ip, setIp] = useState();
  const [loading, setLoading] = useState(true);
  const [payerauth, setPayerAuth] = useState();

  const getBrowser = () => {
    const userAgent = navigator.userAgent;

    if (userAgent.match(/chrome|chromium|crios/i)) {
      return "CHROME";
    } else if (userAgent.match(/firefox|fxios/i)) {
      return "FIREFOX";
    } else if (userAgent.match(/safari/i)) {
      return "SAFARI";
    } else if (userAgent.match(/opr\//i)) {
      return "OPERA";
    } else if (userAgent.match(/edg/i)) {
      return "EDGE";
    } else {
      return "CHROME";
    }
  };

  function addStr(str, index, stringToAdd) {
    return (
      str.substring(0, index) + stringToAdd + str.substring(index, str.length)
    );
  }

  const authenticatePayer = async () => {
    const response = await axios.post(
      `https://staging.seerbitapigateway.com/seerbit/api/3dsv2/payerauth?sredref=${params.sredref}`,
      {
        browser: getBrowser(),
        WindowSize: "FULL_SCREEN",
        AcceptHeaders: "application/json",
        ColorDepth: window.screen.colorDepth,
        JavaEnabled: navigator?.javaEnabled() || false,
        Language: navigator.language,
        ScreenHeight: window.screen.height,
        ScreenWidth: window.screen.width,
        TimeZone: String(new Date().getTimezoneOffset() / 60),
        IpAddress: ip,
      }
    );

    console.log(response, "response")

    if (
      response?.data?.responsehtml &&
      response?.data?.responsecode === "S20"
    ) {
      setPayerAuth({
        ...response.data,
        responsehtml: addStr(
          response.data.responsehtml,
          response.data.responsehtml.indexOf("<iframe ") + 7,
          ` style="border: none" `
        ),
      });
    } else {
      if (response.data.redirecturl) {
        setPayerAuth({
          ...response.data,
          responsehtml: addStr(
            response.data.responsehtml,
            response.data.responsehtml.indexOf("<iframe ") + 7,
            ` style="border: none" `
          ),
        });
        setTimeout(
          () => window.location.assign(response.data.redirecturl),
          3000
        );
      }
    }
    setLoading(false);
  };

  const getHtml = async () => {
    const response = await axiosInstance.get(
      `https://staging.seerbitapigateway.com/seerbit/api/3dsv2/gethtml?sredref=${params.sredref}`
    );
    setSilientHtml(response.data);
  };

  useEffect(() => {
    // console.log(ip)
    if (ip) {
      setTimeout(() => authenticatePayer(), 5000);
    }
  }, [ip]);

  const getIp = async () => {
    const response = await axiosInstance.get(
      "https://api.ipify.org?format=json"
    );
    setIp(response.data.ip);
  };

  useEffect(() => {
    if (params.sredref) {
      getHtml();
      getIp();
    }
  }, [params.sredref]);

  useEffect(() => {
    if (payerauth) {
      const channel = new BroadcastChannel("app-data");
      channel.addEventListener("message", (event) => {
        console.log(event, "event")
        if (
          event?.sredref === params.sredref ||
          event?.data?.sredref === params.sredref
        ) {
          window.location.assign(payerauth.redirecturl);
        }
      });
    }
  }, [payerauth]);

  return (
    <>
      {loading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            flexDirection: "column",
            background: "white",
          }}
        >
          <Loader />
          {/* <img
            src="https://assets.seerbitapi.com/images/seerbit_logo_type.png"
            style={{ width: "230px", height: "30px" }}
            alt="seerbit"
          /> */}
          <div style={{ fontSize: "20px", marginTop: "25px" }}>
            Redirecting to your bank for authorization.
          </div>
        </div>
      )}
      {silientHtml.responsehtml && (
        <iframe
          style={{ display: "none" }}
          srcDoc={silientHtml?.responsehtml}
        />
      )}
      {payerauth?.responsehtml && (
        <iframe
          style={{
            width: "100%",
            height: "100vh",
            background: "white",
            border: "none",
          }}
          srcDoc={payerauth?.responsehtml}
        />
      )}
    </>
  );
};

export default App;
